import { IntercomAPI } from 'react-intercom';

const { REACT_APP_INTERCOM_ID, REACT_APP_INTERCOM_IDENTITY_VERIFICATION_SECRET } = process.env;
const crypto = require("crypto");

export function intercomBoot(){
  let intercomData = JSON.parse(localStorage.getItem('intercom'));
  if(intercomData && REACT_APP_INTERCOM_ID && REACT_APP_INTERCOM_IDENTITY_VERIFICATION_SECRET){
    intercomData.app_id = REACT_APP_INTERCOM_ID;
    intercomData.name = localStorage.getItem('customerFullName');
    intercomData.vertical_padding = 20;
    intercomData.user_id = localStorage.getItem('customerId');
    intercomData.user_hash = crypto.createHmac("sha256", REACT_APP_INTERCOM_IDENTITY_VERIFICATION_SECRET)
      .update(localStorage.getItem('customerId')).digest("hex");
  
    IntercomAPI('boot', intercomData);
    _setupIntervalToTrackCustomerAttributes();
  }
}
export function intercomTrackEvent(eventName){
  IntercomAPI('trackEvent', eventName);
}
export function _setupIntervalToTrackCustomerAttributes() {
  setInterval(() => {
    const record = JSON.parse(localStorage.getItem('currentCustomer'));
    if(record){
      const sanitizedCustomerAttributes = _deserializeAttributes( record )
      IntercomAPI('update', sanitizedCustomerAttributes)
    }
  }, 20 * 1000);
}
export function _deserializeAttributes(customer){
  return {
    'has_flat_pay': !!customer.employer_match && customer.match.length < 2,
    'has_vested_pay': customer.match.length > 1,
    'has_match': customer.has_match,
    'has_advisor': customer.has_advisor,
    'contribution_amount': customer.employer_match,
    'pslf_eligible': customer.pslf_eligible,
    'tlf_eligible': customer.tlf_eligible,
    'nslds_fetch_date': customer.nslds_fetch_date,
    'status': customer.status,
    'employee_or_family': customer.is_employee ? 'employee' : 'family',
    'has_family_invites': customer ? customer.can_invite : false,
    //'lifetime_cap_amount': ((customer && customer.lifetime && customer.lifetime_cap.customer) || customer.lifetime_cap.customer===0) > 0 && customer.lifetime_cap.customer,
    'lifetime_cap_amount' : customer && customer.lifetime_cap ? customer.lifetime_cap.customer : '',
    'has_bill_pay': customer.bill_pay,
    'pay_extra_amount_modeled': customer.extra_payment_cents,
    'refi_term_modeled': customer.refi_term
  }
}