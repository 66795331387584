import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SimpleReactValidator from "simple-react-validator";

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import Need from "../../../common/img/why_i_need.png";

import { nsldsLoanImport, uploadNslds, nsldsLoan2faImport, nsldsAttemptIdCheck, nsldsAttemptLatestCheck } from '../../../actions/advisorActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { Form, Modal, Spinner, InputGroup, FormControl, Button } from 'react-bootstrap';
import { BoxArrowUpRight, Check, QuestionCircle, XLg } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import DragAndDropBox from '../../shared/Drag&DropBox/DragAndDropBox'
import FileList from '../../shared/FileList/FileList'
import WizardSteps from './WizardSteps';
import AddLoanManually from './AddLoanManually';
import CustomModal from '../../shared/CustomModal/CustomModal';
import moment from 'moment';


class Nslds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      stepNumber: 2,
      twoFaMethod: "email",
      twofa_code:"",
      twoFaShown:false,
      isOpen: false,
      before2faLoader: false,
      nsldsAttemptData:null,
      nsldsSuccessRedirectLabel:false,
      addManually: false,
      fileSelected: null,
      saveManualAddedLoan: false,
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        svgType: '', //check, info
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    }
   this.fileRef = React.createRef();

    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor"

    intercomTrackEvent(eventNames.visitedAdvisorNsldsImport);
    this.setState({
      addManually: !!this.props?.location?.state?.addLoanManually
    });
    this.props.nsldsAttemptLatestCheck();
  }

  componentWillUnmount() {
    clearInterval(this.attemptInterval);
  }

  componentDidUpdate(prevProps) {
    // if (this.props.nsldsLoan !== prevProps.nsldsLoan) {
    //   intercomTrackEvent(eventNames.updatedAdvisorNsldsSuccess);
    //   this.props.history.push({
    //     pathname: '/welcome/loans'
    //   })
    // }
    if (this.props.nsldsLoan !== prevProps.nsldsLoan) {
      intercomTrackEvent(eventNames.updatedAdvisorNsldsSuccess); 
      this.setState({
        nsldsAttemptData: this.props.nsldsLoan,
      });

      this.attemptInterval = setInterval(() => {
        this.props.nsldsAttemptIdCheck(this.state.nsldsAttemptData.id);
      }, 7000);
    } 

    if (this.props.nsldsLoan2fa !== prevProps.nsldsLoan2fa) {
      intercomTrackEvent(eventNames.successAdvisorNslds2fa);
      this.setState({
        twoFaShown: false,
        before2faLoader: false,
      })
    }

    if (this.props.nsldsAttemptData !== prevProps.nsldsAttemptData) {
      intercomTrackEvent(eventNames.successAdvisorNsldsAttemptLatest);
      this.setState({
        nsldsAttemptData: this.props.nsldsAttemptData,
      });
      if(!!this.state.nsldsAttemptData) {
        if(this.props.nsldsAttemptData.attributes.status === 'successful') {
          this.setState({
            nsldsSuccessRedirectLabel: true,
            nsldsAttemptData: this.props.nsldsAttemptData,

          })
          clearInterval(this.attemptInterval);
          setTimeout(() =>{
            this.props.history.push({
              pathname:'/welcome/loans'
            })
          }, 5000)
        }
        if(this.props.nsldsAttemptData.attributes.status === 'failed') {
          clearInterval(this.attemptInterval);
          this.setState({
            nsldsSuccessRedirectLabel: false,
            twoFaShown: false,
            before2faLoader: false,
            nsldsAttemptData: this.props.nsldsAttemptData,
          })
        }
      }
    }

    if (this.props.uploadNsldsResponse !== prevProps.uploadNsldsResponse) {

      this.setState({
        ...this.state,
        modalProp: {
          ...this.state.modalProp,
          show: true,
          title: 'Upload successfully',
          bodyText: "You've successfully uploaded your NSLDS file. Redirecting you to loans dashboard in 5 seconds...",
          footerButton: true,
          footerButtonName: "Continue",
          footerButtonOnclick: '',
          footerButtonHref: '/welcome/loans',
          footerButtonOnclickArgs: ''
        }
      });
      intercomTrackEvent(eventNames.successfulAdvisorNsldsFileUpload);
      setTimeout(() =>
        this.props.history.push({
          pathname: '/welcome/loans'
        }), 5000
      )
    }
  }

  handleBack = (e) => {
    intercomTrackEvent(eventNames.visitedAdvisorAboutMe);
    this.props.history.push({
      pathname: '/welcome/about'
    })
  }
  handleSkip = (e) => {
    intercomTrackEvent(eventNames.skippedAdvisorNslds);
    this.props.history.push({
      pathname: '/welcome/loans'
    })
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleMethodSelection = (e) => {

    this.setState({
      "twoFaMethod":e.target.value
    })
  }
  submitNsldsUser = (e) => {

    if (this.validator.allValid()) {

      // this.setState({
      //   loading: true
      // })
      // const nsldsUserRequest = {
      //   "username": this.state.username,
      //   "password": this.state.password
      // }
      // this.props.nsldsLoanImport(nsldsUserRequest)
      // console.log(nsldsUserRequest)
      this.setState({
        before2faLoader: true,
      })

      setTimeout(() => {
        if(!!this.state.nsldsAttemptData) {
          if(this.state.nsldsAttemptData.attributes.status === 'in_progress') {
           this.setState({
             twoFaShown: true
           })
          } 
         }
      },30000)
      const nsldsUserRequest = {
        "username": this.state.username,
        "password": this.state.password,
        "twofa_method": this.state.twoFaMethod
      }
      this.props.nsldsLoanImport(nsldsUserRequest);
    } else {
      this.validator.showMessages();
    }
  }

  submitNslds2fa = (e) => {
    if (this.state.twofa_code.length) {
      const nslds2faRequest = {
        "twofa_code" : this.state.twofa_code,
        "nslds_attempt_id": this.state.nsldsAttemptData.id
      }
      this.props.nsldsLoan2faImport(nslds2faRequest);
    }
  }

  uploadFile = (e) => {
    const { uploadNslds } = this.props;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    uploadNslds(formData)
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  openModal = () => this.setState({
    ...this.state, isOpen: true,
  });
  closeModal = () => this.setState({ ...this.state, isOpen: false });
  setAddManuallyButton = (value) => {
    this.setState({ ...this.state, addManually: value });
  }
  removeFile = () => {
    this.setState({
      fileSelected: null
    })
  }

  setFileData = (file) => {
    this.setState({
      fileSelected: file
    })
  }

  sendAsFormData = () => {
    const { uploadNslds } = this.props;
    const formData = new FormData();
    formData.append("file", this.state.fileSelected);
    uploadNslds(formData);

  }

  formatFileSize = (value) => {
    if (value > 100000) {
      return (Number(value / (1024 * 1024))).toFixed(2) + ' MB'
    } else {
      return (Number(value / (1024 * 1024)) * 1000).toFixed(2) + ' KB'
    }
  }
  handleManualLoanSave = () => {
    this.setState({
      saveManualAddedLoan: !this.state.saveManualAddedLoan,
    });
  }

  render() {
    const { addManually, twoFaShown, before2faLoader, nsldsAttemptData, nsldsSuccessRedirectLabel } = this.state;

    return (

      <>
        <Header hideNotificationAndProfileDropdown={true} hideSubNavBar={true} />
        <div className="G-page-color-background">
          <div className='G-page-main-container my-5'>
            <WizardSteps stepNumber={this.state.stepNumber} />
            <div className='p-4 Gborder-05 bg-white p-4 G-grid11-2 my-3 rounded-lg'>
              <div>
                <div className='pinkCircularDiv mb-4'>
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h3 className='Gfs-175 Gt-slate-5 mb-4'>Import/add your loans</h3>
                <p className='sans-medium'>
                  Please provide the following details to manually add your loan
                  details. Alternatively you can reconnect your FSA account to update
                  your balance and loan information automatically.
                </p>

                <div className="my-4">
                  <div>
                    <div className={`G-addressBox my-2` + (!addManually ? ' selected' : '') + (!!this.props?.location?.state?.addLoanManually ? ' disabled' : '')} onClick={this.setAddManuallyButton.bind(this, false)}>
                      <p className="mb-0">
                        Import loans with your fsa account
                      </p>
                    </div>
                    <div className={`G-addressBox  my-2` + (addManually ? ' selected' : '')} onClick={this.setAddManuallyButton.bind(this, true)}>
                      <p className="mb-0">
                        MANUALLY Add Loans - Not recommended unless you only have private loans
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {nsldsSuccessRedirectLabel && 
                  <p className='Gb-blue-50 p-2'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg> Fetched successfully.You will be redirected in 5 seconds.</p>
                  }
                </div>

              </div>


              {this.state.addManually ?
                <div>
                  <AddLoanManually {...this.props} saveManualAddedLoan={this.state.saveManualAddedLoan} />
                </div>
                :
                // <div>
                //   <img src={Need} className="img-fluid" alt="need" />
                //   <div className='my-4'>
                //     <p className='Gt-slate-4 sans-semibold d-flex align-items-center'>Why do I need this? <QuestionCircle className='ml-1' size={18} data-for="infoTooltip" data-tip role='button' /></p>
                //     <ReactTooltip id="infoTooltip" place="right" effect="solid" multiline>
                //       With one login you can retrieve the <br />
                //       most accurate federal loan data,<br />
                //       including current balance, monthly <br />
                //       payments, and interest rate.
                //       <br /><br />
                //       Data that could take hours to <br />
                //       enter can be added in minutes.
                //     </ReactTooltip>
                //   </div>
                //   <div>
                //     <Form.Group className="mb-3" controlId="formBasicUser">
                //       <Form.Label>FSA ID Username Or Verified Email</Form.Label>
                //       <Form.Control type="email" name='username' placeholder="Username/Email" onChange={this.handleInputChange.bind(this)} />
                //       <ValidatorMessage fieldName='username' fieldValue={this.state.username} validator={this.validator} message="* Username can't be blank" />
                //     </Form.Group>
                //     <Form.Group className="mb-3" controlId="formBasicPwd">
                //       <Form.Label>Password:</Form.Label>
                //       <Form.Control type="password" name='password' placeholder="Password" onChange={this.handleInputChange.bind(this)} />
                //       <ValidatorMessage validator={this.validator} fieldName='password' fieldValue={this.state.password} message="* Password can't be blank" />
                //     </Form.Group>
                //     <p>Don’t know your username or password? <a className='Gt-pink-8' target="_blank" href="https://studentaid.gov/fsa-id/sign-in/landing" rel="noopener noreferrer">Create one or recover it.</a> </p>
                //     <div className='d-flex mob-btn-div-column mb-3'>
                //       <button className='G-orange-button ' onClick={this.submitNsldsUser.bind(this)}>{loading ? 'Loading ...' : 'Import'}</button>
                //     </div>
                //     <div>
                //       <p className='border border-left-0 border-right-0 border-top-0 text-center Gt-slate-4'>OR</p>
                //       <div>

                //         <h4 className='Gfs-125'>You can upload your FSA Loan File here</h4>
                //         <label className='mb2'>
                //           <a className='Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer my-2' onClick={this.openModal}>How do I download my loan details? <BoxArrowUpRight size={18} className='ml-1' /></a>
                //         </label>
                //         <DragAndDropBox setFileData={(file) => this.setFileData(file)} ref={this.fileRef} />
                //       </div>
                //       <div>
                //         {this.state.fileSelected ?
                //           <FileList fileList={new Array(this.state.fileSelected)} removeFile={this.removeFile} />
                //           : null}
                //       </div>
                //     </div>
                //     <div className='d-flex mob-btn-div-column'>
                //       <button className={this.state.fileSelected ? 'G-orange-button' : 'G-disabled-button'} disabled={!this.state.fileSelected} onClick={this.sendAsFormData}> Upload FSA File</button>
                //     </div>
                //     <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                //       <div className='mr-3'>
                //         <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                //           <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                //         </svg>
                //       </div>
                //       <div>
                //         <p className='sans-medium'>Have only private loans? Skip FSA import and we can help
                //           you enter them manually! </p>
                //         <p className='sans-medium'> Have federal and private loans? You can add both types!
                //           We suggest starting with the federal ones and then adding private ones </p>
                //       </div>
                //     </div>
                //   </div>

                // </div>
                <div>
                <img src={Need} className="img-fluid" alt="need" />
                <div className='my-4'>
                  <p className='Gt-slate-4 sans-semibold d-flex align-items-center'>Why do I need this? <QuestionCircle className='ml-1' size={18} data-for="infoTooltip" data-tip role='button' /></p>
                  <ReactTooltip id="infoTooltip" place="right" effect="solid" multiline>
                    With one login you can retrieve the <br />
                    most accurate federal loan data,<br />
                    including current balance, monthly <br />
                    payments, and interest rate.
                    <br /><br />
                    Data that could take hours to <br />
                    enter can be added in minutes.
                  </ReactTooltip>
                </div>
               
                <div>
                  {!!nsldsAttemptData && !!nsldsAttemptData.attributes &&
                <div>
                
                  {nsldsAttemptData.attributes.status === "failed" && <p className='Gb-red-1 p-3'> Synchronization error. Please try again. <br/><strong>{nsldsAttemptData.attributes.result_message}</strong> </p>}
                  {nsldsAttemptData.attributes.status === "in_progress" &&  <p className='Gb-yellow-2 p-3 d-flex justify-content-between'> <span> FSA data is being synchronized.</span><span className='cursor-pointer refreshButton Gb-pink-2'data-for="2faStatusTip" data-tip role='button' onClick={ () =>this.props.nsldsAttemptIdCheck(nsldsAttemptData.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--icon-color)" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                  </svg></span> </p>}
                  {nsldsAttemptData.attributes.status === "successful" && <p className='Gb-green-2 p-3'>synchronized at {moment().local(nsldsAttemptData.attributes.created_at).format('MM-DD-YYYY HH:mm:ss')} </p>}

                <ReactTooltip id="2faStatusTip" place='top' effect='solid'>Refresh</ReactTooltip>
                </div>
                  }
                 
                  <Form.Group className="mb-3" controlId="formBasicUser">
                    <Form.Label>FSA ID Username Or Verified Email</Form.Label>
                    <Form.Control type="email" name='username' placeholder="Username/Email" onChange={this.handleInputChange.bind(this)} />
                    <ValidatorMessage validator={this.validator} customValidationType='includeNoSpecialChar' fieldName='username' fieldValue={this.state.username} message="* Username can' t be blank" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPwd">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" name='password' placeholder="Password" onChange={this.handleInputChange.bind(this)} />
                    <ValidatorMessage validator={this.validator} fieldName='password' fieldValue={this.state.password} message="* Password can't be blank" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='pr-3'>Two-step verification method:</Form.Label>
                    <Form.Check type='radio' inline >
                      <Form.Check.Input type='radio' value='email' checked={this.state.twoFaMethod === 'email'}  onChange={this.handleMethodSelection.bind(this)}  name='2fa'/>
                      <Form.Check.Label>Email</Form.Check.Label>
                    </Form.Check>
                    <Form.Check type='radio' inline>
                      <Form.Check.Input type='radio' value='phone' name='2fa' onChange={this.handleMethodSelection.bind(this)} />
                      <Form.Check.Label color='dark'>SMS</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                 
                  {before2faLoader && !twoFaShown && (<div className='fsaLoader'>
                  <Spinner className='m-auto' color='violet' style={{'color':'#A10066'}} animation="border" />
                  <span className='justify-item-top'>Vault synchronizes your data, this might take a couple of minutes.</span>
                   </div>) }
                  {twoFaShown && <>
                  <label htmlFor="2fa_field">Security Code:</label>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="2FA security code"
                      aria-label="2FA security code"
                      name='twofa_code' 
                      onChange={this.handleInputChange.bind(this)}
                      id='2fa_field'
                    />
                    <InputGroup.Append >
                      <Button type='button' variant="outline-secondary" className='G-orange-button-nopad' onClick={this.submitNslds2fa.bind(this)} >Submit</Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <p className='Gfs-075'>Your security is our priority. This code is used just once to retrieve your loan data and is never stored or reused.</p>
                  </>
                  }
                  <p>Don’t know your username or password? <a className='G-link' target="_blank" href="https://studentaid.gov/fsa-id/sign-in/landing" rel="noopener noreferrer">Create one or recover it.</a> </p>

                  {!before2faLoader && 
                    <div className='d-flex mob-btn-div-column mb-3'>
                    <button className='G-orange-button' disabled={this.before2faLoader} onClick={this.submitNsldsUser.bind(this)} >Import</button>
                  </div> }
                  <div>
                    <p className='border border-left-0 border-right-0 border-top-0 text-center Gt-slate-4'>OR</p>
                    <div>

                      <h4 className='Gfs-125'>You can upload your FSA Loan File here</h4>
                      <label className='mb2'>
                        <button className='Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer my-2' onClick={this.openModal}>How do I download my loan details? <BoxArrowUpRight size={18} className='ml-1' /></button>
                      </label>
                      <DragAndDropBox setFileData={(file) => this.setFileData(file)} ref={this.fileRef} />
                    </div>
                    <div>
                      {this.state.fileSelected ?
                        <FileList fileList={new Array(this.state.fileSelected)} removeFile={this.removeFile} />
                        : null}
                    </div>
                  </div>
                  <div className='d-flex mob-btn-div-column'>
                    <button className={this.state.fileSelected ? 'G-orange-button' : 'G-disabled-button'} disabled={!this.state.fileSelected} onClick={this.sendAsFormData}> Upload FSA File</button>
                  </div>

                  <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                    <div className='mr-3'>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                      </svg>
                    </div>
                    <div>
                      <p className='sans-medium'>Have only private loans? Skip FSA import and we can help
                        you enter them manually! </p>
                      <p className='sans-medium'> Have federal and private loans? You can add both types!
                        We suggest starting with the federal ones and then adding private ones </p>
                    </div>
                  </div>
                </div>

              </div>
              }
            </div>
            <div className="d-flex justify-content-end p-3 mob-btn-div-column">
              <button type="button" className="G-cancel-button ml-3 mt-2" onClick={this.handleBack.bind(this)}>
                Go Back
              </button>
              {this.state.addManually ?
                <button type="button" className="G-orange-button ml-3 mt-2" onClick={this.handleManualLoanSave.bind(this)}>
                  Continue
                </button>
                :
                <button type="button" className="G-orange-button ml-3 mt-2" onClick={this.handleSkip.bind(this)}>
                  Continue
                </button>
              }
            </div>
          </div>
        </div>

        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="d-flex">
              <div className="pinkCircularDiv mb-3 mr-3">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M27.5 38.75H31.25V42.5H27.5V38.75ZM30 17.5C36.6875 17.775 39.6 24.525 35.625 29.5875C34.5875 30.8375 32.9125 31.6625 32.0875 32.7125C31.25 33.75 31.25 35 31.25 36.25H27.5C27.5 34.1625 27.5 32.4 28.3375 31.15C29.1625 29.9 30.8375 29.1625 31.875 28.3375C34.9 25.5375 34.15 21.575 30 21.25C29.0054 21.25 28.0516 21.6451 27.3483 22.3483C26.6451 23.0516 26.25 24.0054 26.25 25H22.5C22.5 23.0109 23.2902 21.1032 24.6967 19.6967C26.1032 18.2902 28.0109 17.5 30 17.5Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <div>
                <div className="d-flex">
                  <h4 className='py-3'>How do I download my loan details?</h4>
                  <div> <button onClick={this.closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                </div>
                <p className="sans-medium">
                  <ol className='pl-3'>
                    <li className='sans-medium'>Sign into your account at <a className='G-link' target="_blank" href="https://studentaid.gov/fsa-id/sign-in/landing" rel="noopener noreferrer"> Federal Student Aid.</a></li>
                    <li className='sans-medium'>In the upper right hand corner of the “My Aid” section, click on “View Details”.</li>
                    <li className='sans-medium'>In the upper right hand corner of the “My Aid Summary” page, click on “Download My Aid Data” (If you’re using a mobile device, rotate it horizontally to see this option.)</li>
                  </ol>
                  <p className='sans-medium'>After you click Continue to finish the download, a file called MyStudentData.txt will download to your computer. You can upload that file to the right.</p>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Footer />
        <CustomModal svgComponent={
          <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div>
        }
          modalProp={{ ...this.state.modalProp }} />
      </>
    )
  }
}
Nslds.propTypes = {
  nsldsLoanImport: PropTypes.func.isRequired,
  nsldsLoan2faImport: PropTypes.func.isRequired,
  nsldsAttemptIdCheck: PropTypes.func.isRequired,
  nsldsAttemptLatestCheck: PropTypes.func.isRequired,
  uploadNslds: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  nsldsLoan: state.advisor.nsldsLoan,
  nsldsLoan2fa: state.advisor.nsldsLoan2fa,
  nsldsAttemptData: state.advisor.nsldsAttemptData,
  uploadNsldsResponse: state.advisor.uploadNsldsResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { nsldsLoanImport, uploadNslds, nsldsLoan2faImport, nsldsAttemptIdCheck, nsldsAttemptLatestCheck })(Nslds);
