import React, { useState } from 'react'
import { BoxArrowUpRight, XLg, Envelope } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";

export const TableTrimData = ({ message }) => {
    const [isOpen, setIsOpen] = useState(false)
    const trimMsg = (msg) => {
        if (msg.length === 0) {
            return '-';
        }
        if (msg.length > 20) {
            return msg.slice(0, 20) + "..."
        } else {
            return msg;
        }
    }
    const showModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }
    return (
        <>{trimMsg(message)}{message.length > 20 ? (<span onClick={showModal}><BoxArrowUpRight className='Gt-pink-8 ml-2 mb-1 G-cursor-pointer' /></span>) : ""}

            {/* Modal code */}
            <Modal size="lg" show={isOpen} onHide={closeModal}>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="pinkCircularDiv mb-3 mr-3 px-3">
                            <Envelope className='Gt-pink-8' size={30} />
                        </div>
                        <div>
                            <div className="d-flex">
                                <h4 className='py-3'>Message</h4>
                                <div> <button onClick={closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                            </div>
                            <p className="sans-medium">
                                {message}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
